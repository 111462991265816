<template>
  <v-card class="ma-4">
    <v-dialog v-model="dialogAddItem" fullscreen
              hide-overlay
              transition="dialog-bottom-transition">

      <v-card v-if="dialogAddItem">
        <v-toolbar
            color="#082d3c"
            dark
        >
          <v-btn
              dark
              icon
              @click="closeProfile"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items v-if="tab!==4">
            <v-chip v-if="hasRole(roleAdmins()) && !isActive" class=" ma-4" color="green" dark @click="changeActive(true)">Approve</v-chip>
            <v-btn
                :disabled="(!valid || buttonLoading)"
                :loading="buttonLoading"
                dark
                text
                @click.stop="validate(tab+1)"
            >{{ btnTitle }}
            </v-btn>
          </v-toolbar-items>

          <template v-slot:extension>
            <v-tabs
                v-model="tab"
                center-active
                centered

            >
              <v-tabs-slider color="amber"></v-tabs-slider>

              <v-tab
                  v-for="(tabItem,index) in tabItems"
                  :key="index"
                  v-model="tab"
                  @click.stop="validate(index)"

              >
                {{ tabItem }}
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <v-alert
            v-if="Object.keys(errors).length>0"
            dense
            text
            type="error"
        >
          <h4 class="subtitle">Correct the following errors:</h4>
          <ul>
            <li
                v-for="(error, index) in errors"
                :key="index"
            >{{ error.message || error }}
            </li>
          </ul>
        </v-alert>

        <v-card-text>
          <v-container>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
              <v-tabs-items v-model="tab">
                <v-card class="ma-4 pa-3" elevation="8">
                  <v-card-text>
                    <v-tab-item
                        v-for="tabItem in tabItems"
                        :key="tabItem"
                        class="mt-4">

                      <v-row v-if="tab==0">

                        <v-col v-if="imageUrl" class="flex" cols="12">
                          <div class="d-flex justify-center ma-4">
                            <v-avatar size="110">
                              <v-img
                                  :disabled="isActive"
                                  :src="imageUrl"
                                  @click="previewImage(imageUrl,true)"
                              ></v-img>
                            </v-avatar>
                          </div>
                        </v-col>


                        <v-col cols="12" lg="3" md="3" sm="6">
                          <v-text-field v-model="companyName" :readonly="isActive" :rules="[v => !!v || 'Company Name is required']"
                                        label="Company Name"
                                        outlined required></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="3" md="3" sm="6">
                          <v-text-field v-model="gasSafeNumber" :readonly="isActive"
                                        :rules="[v => !!v || 'Gas Safe number is required']"
                                        label="Company Gas Safe number"
                                        outlined required @keypress="onlyNumber"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="3" md="3" sm="6">
                          <v-text-field v-model="gasSafeLicense" :readonly="isActive"
                                        :rules="[v => !!v || 'Gas Safe license is required']"
                                        label="Your Gas Safe license number"
                                        outlined required @keypress="onlyNumber"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="3" md="3" sm="6">
                          <v-text-field v-model="houseNumber" :readonly="isActive" label="House Number" outlined></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="3" md="3" sm="6">
                          <v-text-field v-model="accountName" :readonly="isActive" :rules="[v => !!v || 'Account Holder Name is required']"
                                        label="Account Holder Name"
                                        outlined required></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="3" md="3" sm="6">
                          <v-text-field v-model="accountCode" :readonly="isActive"
                                        :rules="[v => !!v || 'account code is required']"
                                        label="Account sort code "
                                        outlined required @keypress="onlyNumber"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="3" md="3" sm="6">
                          <v-text-field v-model="accountNumber" :readonly="isActive"
                                        :rules="[v => !!v || 'account number is required']"
                                        label="Account number"
                                        outlined required @keypress="onlyNumber"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                          <v-autocomplete
                              v-model="suggestionCompanyAddress"
                              :items="itemsCompanyAddress"
                              :loading="isLoadingCompanyAddress"
                              :readonly="isActive"
                              :search-input.sync="companyAddressSearch"
                              clearable
                              hide-selected
                              item-text="suggestion"
                              item-value="id"
                              label="Auto select company address"
                              no-filter
                              outlined
                              placeholder="Start typing to search for company address"
                              return-object
                              single-line
                          ></v-autocomplete>
                        </v-col>

                        <v-col cols="6" lg="3" md="3" sm="6">
                          <v-text-field v-model="companyCity" :readonly="isActive" :rules="[v => !!v || 'Company city is required.']"
                                        clearable label="Company City"
                                        outlined></v-text-field>
                        </v-col>

                        <v-col cols="6" lg="3" md="3" sm="6">
                          <v-text-field v-model="companyPostcode" :readonly="isActive"
                                        :rules="[v => !!v || 'Company postcode is required.']"
                                        clearable label="Company Postcode"
                                        outlined></v-text-field>
                        </v-col>

                        <v-col cols="6" lg="3" md="3" sm="6">
                          <v-text-field v-model="companyAddressLine1" :readonly="isActive" :rules="addressLine1Rules" clearable
                                        label="Company address Line 1"
                                        outlined></v-text-field>
                        </v-col>

                        <v-col cols="6" lg="3" md="3" sm="6">
                          <v-text-field v-model="companyAddressLine2" :readonly="isActive" :rules="addressLine2Rules" clearable
                                        label="Company address Line 2"
                                        outlined></v-text-field>
                        </v-col>

                        <v-col cols="6">
                          <v-radio-group v-model="isLimitedCompany" :readonly="isActive" inline="true" mandatory>
                            <template v-slot:label>
                              <div>Company type</div>
                            </template>
                            <div class="d-inline-flex align-start ml-3">
                              <v-radio :value="true" label="Limited Company"></v-radio>
                              <v-radio :value="false" class="ml-6" label="Sole Trader"></v-radio>
                            </div>
                          </v-radio-group>
                        </v-col>


                        <v-col cols="6">
                          <v-radio-group v-model="isVatRegistered" :readonly="isActive" inline="true" mandatory>
                            <template v-slot:label>
                              <div>VAT Registered?</div>
                            </template>
                            <div class="d-inline-flex align-start ">
                              <v-radio :value="true" label="Yes"></v-radio>
                              <v-radio :value="false" class="ml-6" label="No"></v-radio>
                            </div>
                          </v-radio-group>
                        </v-col>

                        <v-col cols="6">
                          <v-text-field v-if="isLimitedCompany" v-model="companyNumber" :readonly="isActive"
                                        :rules="[v => !!v || 'Company Number is required.']" label="Company Number"
                                        outlined
                                        required @keypress="onlyNumber"></v-text-field>
                        </v-col>

                        <v-col cols="6">
                          <v-text-field v-if="isVatRegistered" v-model="vatNumber" :disabled="isActive"
                                        :rules="[v => !!v || 'Vat Number is required.']" label="Vat Number"
                                        outlined
                                        required @keypress="onlyNumber"></v-text-field>
                        </v-col>


                        <v-col cols="12" lg="3" md="4" sm="8">
                          <v-file-input
                              v-model="image"
                              :disabled="isActive"
                              :rules="(id>0)?[]:imageRules"
                              accept="image/png, image/jpeg, image/bmp"
                              label="Upload Selfie/Headshot"
                              outlined
                              placeholder="Profile Image"
                              prepend-icon="mdi-camera"
                          ></v-file-input>
                        </v-col>
                      </v-row>

                      <v-row v-if="tab==1">

                        <v-col cols="12">
                          <div class="d-flex flex-column">
                            <div v-for="itemSkill in itemSkills"
                                 :key="itemSkill.id">
                              <div class="black--text text-body-1 ">{{ itemSkill.name }}</div>
                              <div v-for="parent in skills.filter(c => c.parentID==itemSkill.id && c.isActive===true)"
                                   :key="parent.id"
                                   class="d-inline-flex mx-4">
                                <v-checkbox v-model="skill" :label="parent.name" :value="parent.id" light readonly>
                                </v-checkbox>
                              </div>
                            </div>
                          </div>
                        </v-col>

                      </v-row>

                      <div v-if="tab==2">
                        <v-row v-if="id>0">

                          <v-col v-for="(imageRow,index) in imageRows" :key="index" cols="6" lg="4" md="6"
                                 sm="12">
                            <v-card>
                              <v-img
                                  :src="imageRow.imageThumbnail"
                                  class="grey darken-4"
                                  height="200"
                                  :disabled="(imageRow.imageUrl?.indexOf('.pdf') !== -1)"
                                  @click="previewImage(imageRow.imageUrl,true)"
                              ></v-img>
                              <v-card-title class="text-body-2  ">
                                {{ imageRow.title }} &nbsp; <span v-if="(imageRow.imageUrl?.indexOf('.pdf') !== -1)" class="text-body-2">  <a :href="imageRow.imageUrl" target="_blank"> View</a></span>
                              </v-card-title>
                              <v-card-text>
                                <v-text-field
                                    v-model="imageRow.expireDate"
                                    label="Expire date"
                                    outlined
                                    readonly
                                    type="date"
                                ></v-text-field>
                              </v-card-text>
                            </v-card>
                          </v-col>


                        </v-row>

                      </div>

                      <v-row v-if="tab==3">

                        <v-col cols="12" md="12" sm="12">
                          <v-autocomplete
                              v-model="coverage"
                              :items="postcodes"
                              :rules="[v => !!(v && v.length) || 'please select coverage from list']"
                              chips
                              clearable
                              hide-details
                              item-text="name"
                              item-value="id"
                              label="please select coverage from menu"
                              multiple
                              outlined
                              required
                              return-object
                          ></v-autocomplete>
                        </v-col>

                      </v-row>

                      <v-form
                          v-if="Number(tab)===4"
                          ref="form"
                          v-model="valid"
                          class="mt-4"
                          lazy-validation>
                        <v-row>


                          <v-col v-if="isNewNote" cols="12" lg="4" md="4" sm="6">
                            <v-text-field v-model="noteAdminTitle"
                                          label="Note Title"
                                          outlined required></v-text-field>

                          </v-col>

                          <v-col v-if="isNewNote" cols="12">
                            <v-textarea v-model="noteAdminBody" :counter="1000" :disabled="buttonLoading"
                                        :rules="noteRules " clearable label="Note Body"
                                        no-resize outlined
                                        rows="5"
                            ></v-textarea>
                          </v-col>

                          <v-col cols="12" lg="6" md="4" sm="2">
                            <v-btn
                                :disabled=" buttonLoadingAssign "
                                :loading="buttonLoadingAssign"
                                class="mt-2"
                                color="green"
                                dark
                                filled
                                @click="clickSaveNote"
                            >{{ !isNewNote ? 'New Note' : 'Save Note' }}
                            </v-btn>
                          </v-col>

                          <v-col cols="12">


                            <v-simple-table>
                              <template v-slot:default>
                                <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Title</th>
                                  <th>Created Date</th>
                                  <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                    v-for="(item,index) in noteProfiles"
                                    :key="item.id"
                                >
                                  <td>
                                    <v-chip>{{ index + 1 }}</v-chip>
                                  </td>
                                  <td>{{ item.title }}</td>
                                  <td>{{ convertTimeToDate(item.createdAt) }}</td>
                                  <td>
                                    <v-btn
                                        class="ma-2"
                                        color="orange"
                                        dark
                                        outlined
                                        @click="showNote(item)"
                                    >
                                      Show
                                      <v-icon
                                          dark
                                          right
                                      >
                                        mdi-checkbox-marked-circle
                                      </v-icon>
                                    </v-btn>
                                    <v-btn
                                        class="ma-2"
                                        color="blue"
                                        dark
                                        outlined
                                        @click="editNote(item)"
                                    >
                                      Edit
                                      <v-icon
                                          dark
                                          right
                                      >
                                        mdi-checkbox-marked-circle
                                      </v-icon>
                                    </v-btn>
                                    <v-btn
                                        color="red"
                                        dark
                                        outlined
                                        @click="showDeleteDialog(item)"
                                    >

                                      Delete
                                      <v-icon right>
                                        mdi-delete
                                      </v-icon>
                                    </v-btn>
                                  </td>

                                </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-col>
                        </v-row>
                      </v-form>

                      <Available v-if="tab===5"/>

                    </v-tab-item>
                  </v-card-text>
                </v-card>

              </v-tabs-items>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-if="showDialogNote"
              v-model="showDialogNote"
              width="750"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="red lighten-2"
            dark
            v-bind="attrs"
            v-on="on"
        >
          Click Me
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 text--white grey lighten-1 ">
          {{ noteAdminTitle }}
        </v-card-title>

        <v-card-text class="text-h6">
          {{ noteAdminBody }}
        </v-card-text>


        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="showDialogNote = false"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="showDialogDeleteDoc" v-model="showDialogDeleteDoc"
              max-width="290"
              persistent
    >

      <v-card>
        <v-card-title class="text-h5">
          Delete Data
        </v-card-title>
        <v-card-text>Do you want to continue process this operation ?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="red darken-1"
              text
              @click="processDeleteOP"
          >
            Delete
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="showDialogDeleteDoc = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="showDialogImage" v-model="showDialogImage" max-width="30%" @keydown.esc="showDialogImage=false">
      <v-card>
        <v-img :src="urlImageDialog" alt="" class="fill-height" contain/>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  pathImageUrl,
  pathPdfUrl,
  roleAdmins,
  urlCitiesPostcodes,
  urlCreateNoteProfile,
  urlCreateProfile,
  urlImageTypes,
  urlNoteProfiles,
  urlPostcodeApi,
  urlPostcodeAutoComplete,
  urlPostcodeKey,
  urlProfiles,
  urlSkills,
  urlUpdateApproveProfile,
  urlUpdateNoteProfile,
  urlUpdateProfile,
} from "@/config";
import networks from "@/services/networks";
import {hasRole} from "@/services/globalFunction";
import Available from "@/views/eng/Available.vue";

export default {
  components: {Available},
    data: () => ({
      valid: true,
      errors: [],
      buttonLoading: false,
      dialogAddItem: true,
      userID: 0,
      id: 0,
      tab: 0,
      tabItems: ['Company Details', 'Qualifications', 'Documentation', 'Coverage', 'Notes', "Availables"],
      isActive: false,
      urlImageDialog: null,
      /// form 1 fields
      accountName: null,
      accountCode: null,
      accountNumber: null,
      gasSafeLicense: null,
      gasSafeNumber: null,
      companyName: null,
      urlSelectedCompanyAddress: null,
      selectedCompanyAddress: null,
      entriesCompanyAddress: [],
      isLoadingCompanyAddress: false,
      suggestionCompanyAddress: null,
      companyAddressSearch: null,
      companyType: 0,
      isLimitedCompany: true,
      companyNumber: null,
      isVatRegistered: true,
      vatNumber: null,
      houseNumber: null,
      image: null,
      imageUrl: null,
      imageRules: [
        v => !!v || 'Image is required',
        v => !v || !v.length || v[0].size < 2000000 || 'Image size should be less than 2 MB!',
      ],

      addressLine1Rules: [
        v => !!v || 'address line 1 is required',
        v => (v && v.length <= 150) || 'address line 1 must be less than 150 characters',
      ],
      addressLine2Rules: [
        v => (!v || v.length <= 150) || 'address line 2 must be less than 150 characters',
      ],

      ///// form 2 fields
      skills: [],
      skill: [],
      itemSkills: [],

      ///// form 3 fields
      imageTypes: [],
      imageRows: [],
      imageRow: {id: null, title: null, image: null, expireDate: null, imageUrl: null},

      ///// form 4 fields
      postcodes: [],
      coverage: [],

      loading: true,
      isFormTitleEdit: false,
      btnTitle: "Next",
      showDialogImage: false,

      ///// form 5 fields
      isNewNote: false,
      noteAdminTitle: null,
      noteAdminBody: null,
      noteAdminID: null,
      isEditNoteMode: false,
      noteProfiles: [],
      showDialogNote: false,
      noteRules: [
        v => (!v || v.length <= 1000) || 'This Field must be less than 100 characters',
      ],
      buttonLoadingAssign: false,
      showDialogDeleteDoc: false,


    }),

    created() {
      this.userID = this.$route.params.id ?? 0;
      this.getPostcodesCityFromApi();
      this.getSkillsFromApi();
      this.getImageTypesFromApi();
      this.getProfileFromApi();


    },


    computed: {
      formTitle() {
        return this.isFormTitleEdit ? 'Edit profile information' : 'Add profile information'
      },

      companyAddressLine1() {
        if (!this.selectedCompanyAddress) return null;

        return this.selectedCompanyAddress.line_1;
      },
      companyAddressLine2() {
        if (!this.selectedCompanyAddress) return null;

        return this.selectedCompanyAddress.line_2;
      },
      companyCity() {
        if (!this.selectedCompanyAddress) return null;

        return this.selectedCompanyAddress.post_town;
      },
      companyPostcode() {
        if (!this.selectedCompanyAddress) return null;

        return this.selectedCompanyAddress.postcode;
      },

      itemsCompanyAddress() {
        return this.entriesCompanyAddress.map(entry => {
          const suggestion = entry.suggestion;

          return Object.assign({}, entry, {suggestion})
        })
      },

    },

    watch: {
      tab() {
        this.btnTitle = "Next";
        if (this.tab === 3) {
          this.btnTitle = "Save";
        }
      },
      dialogAddItem() {
        if (!this.dialogAddItem) {
          this.isFormTitleEdit = false;
          this.clear();
        }
      },

      isLimitedCompany() {
        if (!this.isLimitedCompany) {
          this.companyNumber = null;
        }
      },

      isVatRegistered() {
        if (!this.isVatRegistered) {
          this.vatNumber = null;
        }
      },

      companyAddressSearch(val) {

        if (this.isLoadingCompanyAddress) return;

        this.isLoadingCompanyAddress = true;

        fetch(urlPostcodeApi + urlPostcodeAutoComplete + val + '&' + urlPostcodeKey)
            .then(res => res.json())
            .then(res => {
              this.entriesCompanyAddress = res.result.hits;
            })
            .catch(err => {
              console.log(err)
            })
            .finally(() => (this.isLoadingCompanyAddress = false))
      },

      suggestionCompanyAddress() {
        if (!this.suggestionCompanyAddress) return null;

        this.urlSelectedCompanyAddress = this.suggestionCompanyAddress.urls.udprn;
      },

      urlSelectedCompanyAddress() {

        if (this.isLoadingCompanyAddress) return;

        this.isLoadingCompanyAddress = true;

        fetch(urlPostcodeApi + this.urlSelectedCompanyAddress + '?' + urlPostcodeKey)
            .then(res => res.json())
            .then(res => {
              this.selectedCompanyAddress = res.result;
            })
            .catch(err => {
              console.log(err)
            })
            .finally(() => (this.isLoadingCompanyAddress = false))
      },


    },

    methods: {
      roleAdmins() {
        return roleAdmins
      },
      hasRole,
      getProfileFromApi() {
        this.loading = true;
        networks.fetchFromWeb(urlProfiles + this.userID)
            .then(response => {

              if (response.data != null && response.data !== "") {
                this.editItem(response.data);
              }
              this.loading = false;
            })
            .catch(error => {
              alert(error);
            });
      },


      getSkillsFromApi() {
        this.loading = true;
        networks.fetchFromWeb(urlSkills)
            .then(response => {
              this.skills = response.data;
              this.itemSkills =
                  this.skills.filter(c => c.parentID == 0).concat(this.itemSkills);
              this.loading = false;
            })
            .catch(error => {
              alert(error);
            });
      },

      getPostcodesCityFromApi() {
        networks.fetchFromWeb(urlCitiesPostcodes)
            .then(response => {
              response.data.forEach(city => {
                this.postcodes.push({header: city.name});

                city.Postcodes.forEach(postcode => {
                  this.postcodes.push({id: postcode.id, name: postcode.name, city: city.name});
                });
                this.postcodes.push({divider: true});
              });
            })
            .catch(error => {
              alert(error);
            });
      },


      getImageTypesFromApi() {
        this.loading = true;
        networks.fetchFromWeb(urlImageTypes)
            .then(response => {
              this.imageTypes = response.data.filter(c => c.parentID === 2).concat(this.imageTypes);
              this.imageTypes.forEach((imageType) => this.imageRows.push({id: imageType.id, title: imageType.name}));
              this.loading = false;
            })
            .catch(error => {
              alert(error);
            });
      },

      getNoteForProfileFromApi() {
        this.loading = true;
        networks.fetchFromWeb(urlNoteProfiles + this.id)
            .then(response => {
              this.noteProfiles = response.data;
              this.loading = false;
            })
            .catch(error => {
              alert(error);
            });
      },


      onlyNumber($event) {
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
          $event.preventDefault();
        }

      },

      previewImage(url, isShowDialog = false) {

        if (isShowDialog && !(url.indexOf('.pdf') !== -1)) {
          this.showDialogImage = true;
          this.urlImageDialog = url;
        }

      },


      closeProfile() {
        this.dialogAddItem = false;
        this.$router.push(hasRole("admin") ? '/users' : '/');
      },

      changeActive(isActive) {
        networks.fetchFromWeb(urlUpdateApproveProfile + this.userID, 2, {isActive: isActive})
            .then(response => {

              if (response.status === 200) {
                alert("the data has been edited successfully");
                this.$router.push("/users");
              }


            })
            .catch(error => {
              this.errors = error.response.data.errors || error.response.data.error || error.response.data;
              this.buttonLoading = false;
            });
      },

      editNote(item) {
        this.noteAdminTitle = item.title;
        this.noteAdminBody = item.body;
        this.noteAdminID = item.id;
        this.isEditNoteMode = true;
        this.isNewNote = true;
      },

      showNote(item) {
        this.isNewNote = false;
        this.noteAdminTitle = item.title;
        this.noteAdminBody = item.body;
        this.showDialogNote = true;
      },

      convertTimeToDate(timeStamp, isFullDate = false) {
        const date = new Date(timeStamp);
        if (!isFullDate) {
          return date.toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'});
        } else {
          return date.toLocaleDateString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          }) + "  " + date.toLocaleTimeString('en-US');
        }
      },

      clickSaveNote() {
        if (!this.isNewNote) {
          this.isNewNote = true;

        } else {
          if (this.noteAdminTitle) {

            let data = {
              title: this.noteAdminTitle,
              body: this.noteAdminBody,
              profileID: this.id,
            };

            if (this.isEditNoteMode) {
              networks.fetchFromWeb(urlUpdateNoteProfile + this.noteAdminID, 2, data)
                  .then(response => {
                    if (response.status === 200) {
                      this.isEditNoteMode = false;
                      alert("the data has been saved successfully");
                      this.errors = [];
                      this.noteAdminID = null;
                      this.noteAdminTitle = null;
                      this.noteAdminBody = null;
                      this.isNewNote = false;
                      this.getNoteForProfileFromApi();
                    }
                  })
                  .catch(error => {
                    this.errors = error.response.data.errors || error.response.data.error;
                    this.buttonLoadingAssign = false;

                  });
            } else {
              networks.fetchFromWeb(urlCreateNoteProfile, 1, data)
                  .then(response => {

                    if (response.status === 200) {
                      this.isEditNoteMode = false;
                      alert("the data has been saved successfully");
                      this.errors = [];
                      this.noteAdminID = null;
                      this.noteAdminTitle = null;
                      this.noteAdminBody = null;
                      this.isNewNote = false;
                      this.getNoteForProfileFromApi();
                    }


                  })
                  .catch(error => {
                    this.errors = error.response.data.errors || error.response.data.error;
                    this.buttonLoadingAssign = false;

                  });
            }


          } else {
            alert("The title of note is required");
          }
        }

      },

      showDeleteDialog(item) {
        this.deleteRowID = item.id;

        this.showDialogDeleteDoc = true;
      },

      processDeleteOP() {
        let url = urlNoteProfiles;
        this.showDialogDeleteDoc = false;

        networks.fetchFromWeb(url + this.deleteRowID, 3)
            .then(response => {
              if (response.status === 200) {
                alert("Data has been deleted");
                this.getNoteForProfileFromApi();
              }
            })
            .catch(() => {
              alert("Failed for process delete data");
            });


      },


      validate(tabIndex) {

        if (tabIndex === 4) return;

        if (this.$refs.form.validate()) {

          if (tabIndex === 4) {
            this.buttonLoading = true;
            this.errors = [];
            this.saveItem();
          } else {
            this.btnTitle = "Next";
            this.tab = tabIndex;
            this.valid = true;
            this.buttonLoading = false;

          }
        }
      },

      saveItem() {
        const fd = new FormData();
        fd.append("accountName", this.accountName);
        fd.append("accountCode", this.accountCode);
        fd.append("accountNumber", this.accountNumber);
        fd.append("houseNumber", this.houseNumber);
        if (this.image != null) fd.append("avatar", this.image);
        fd.append("companyName", this.companyName);
        fd.append("companyGasNumber", this.gasSafeNumber);
        fd.append("companyLicenseNumber", this.gasSafeLicense);
        fd.append("companyAddress1", this.companyAddressLine1);
        fd.append("companyAddress2", this.companyAddressLine2);
        fd.append("companyCity", this.companyCity);
        fd.append("companyPostcode", this.companyPostcode);
        fd.append("isLimitedCompany", this.isLimitedCompany);
        if (this.isLimitedCompany) fd.append("companyNumber", this.companyNumber);
        fd.append("isVatRegistered", this.isVatRegistered);
        if (this.isVatRegistered) fd.append("vatNumber", this.vatNumber);
        fd.append("postcodeIDs", this.coverage.map((e) => e.id));
        fd.append("skillIDs", this.skill);
        if (this.imageRows.length > 0) this.imageRows.forEach((e) => fd.append("gallery", e.image));
        if (this.imageRows.length > 0) fd.append("galleryIDs", this.imageRows.map((e) => e.id));
        if (this.imageRows.length > 0) fd.append("galleryExpires", this.imageRows.map((e) => e.expireDate));
        if (this.imageRows.length > 0 && this.isFormTitleEdit) fd.append("galleryNames", this.imageRows.map((e) => e.image ? e.image.name : ""));

        if (this.isFormTitleEdit)
          networks.fetchFromWeb(urlUpdateProfile + this.userID, 2, fd, true)
              .then(response => {

                if (response.status === 200) {
                  alert("the data has been edited successfully");
                  this.getProfileFromApi();
                }


              })
              .catch(error => {
                this.errors = error.response.data.errors || error.response.data.error || error.response.data;
                this.buttonLoading = false;
              });
        else {

          networks.fetchFromWeb(urlCreateProfile, 1, fd,true)
              .then(response => {

                if (response.status == 200) {
                  this.clear();
                  alert("the data has been added successfully");
                  this.getProfileFromApi();
                }


              })
              .catch(error => {
                this.errors = error.response.data.errors || error.response.data.error || error.response.data;
                this.buttonLoading = false;

              });
        }

      },

      clear() {
        if (this.$refs.form)
          this.$refs.form.resetValidation();

        this.tab = 0;
        this.city = null;
        this.id = 0;
        this.image = null;
        this.imageRows = [];
        this.imageTypes.forEach((imageType) => this.imageRows.push({id: imageType.id, title: imageType.name}));

        this.valid = true;
        this.errors = [];
        this.buttonLoading = false;

        ////// Tab Form 5 index
        this.noteProfiles = [];
        this.noteAdminID = null;
        this.noteAdminBody = null;
        this.noteAdminTitle = null;

      },

      editItem(item) {
        this.clear();
        this.isFormTitleEdit = true;
        this.id = item.id;

        this.accountName = item.accountName;
        this.accountCode = item.accountCode;
        this.accountNumber = item.accountNumber;
        this.houseNumber = item.houseNumber;
        this.houseName = item.houseName;
        this.imageUrl = pathImageUrl + item.image;
        this.companyName = item.companyName;
        this.gasSafeNumber = item.companyGasNumber;
        this.gasSafeLicense = item.companyLicenseNumber;
        this.isLimitedCompany = item.isLimitedCompany;
        this.isVatRegistered = item.isVatRegistered;
        this.companyNumber = item.companyNumber;
        this.vatNumber = item.vatNumber;
        this.isActive = item.isActive;
        this.selectedCompanyAddress = {
          line_1: item.companyAddress1,
          line_2: item.companyAddress2,
          post_town: item.companyCity,
          postcode: item.companyPostcode,
        };

        this.skill = item.Skills.map((e) => e.id);
        this.coverage = item.Postcodes.map((e) => {
          return {"id": e.id};
        });


        let imageProfile = null;
        this.imageRows.forEach((imageRow, index) => {
          imageProfile = item.ImageProfiles.find((imageProfile) => imageProfile.imageTypeID === imageRow.id);
          if (imageProfile != null) {
            this.imageRows[index] = Object.assign({}, this.imageRows[index], {
              imageUrl: (((imageProfile.image.indexOf(".pdf") !== -1) ? pathPdfUrl : pathImageUrl) + imageProfile.image),
              imageThumbnail: pathImageUrl + ((imageProfile.image.indexOf(".pdf") !== -1) ? '.pdf' : imageProfile.image),
              expireDate: imageProfile.expire,
            });
          }
        });


        this.dialogAddItem = true;

        ////// Tab Form 5 index
        this.noteProfiles = item.NoteProfiles;
        this.isEditNoteMode = false;
      },


    },
  }
</script>


